import { getActivePinia } from 'pinia'

import { useUserStore } from '~/stores/user'

/**
 * This session has a logged in user.
 * In the case the user tried to access a page that needed it to be logged in the redirection will include a next query.
 */
export default defineNuxtRouteMiddleware((to) => {
  const { $localePath } = useNuxtApp()
  const pinia = getActivePinia()
  const { IS_LOGGED_IN } = useUserStore(pinia)

  if (IS_LOGGED_IN) return

  return navigateTo(
    $localePath({
      path: '/',
      query: {
        next: to.fullPath,
      },
    }),
  )
})
